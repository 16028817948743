// This file is auto generated. Do not edit this file manually.

export const assets = {
  KOBOT_HAPPY_LARGE: "/assets/77ed33cf9fb532c16ced0f4c957c1e62.svg",
  KOBOT_SAD_LARGE: "/assets/0d4362f6d2873a776ca6cde91c547517.svg",
  KOBOT_HAPPY_EXTRA_SMALL: "/assets/5b28f56b73d4f6101e0d80cf3925c73e.svg",
  KOBOT_HAPPY_EXTRA_SMALL_GREY: "/assets/1b74b47ad4f9e14b532cecfcd6ebc93b.svg",
  KOBOT_SAD_EXTRA_SMALL_GREY: "/assets/1fd495b6e486d00891339aac22b79cfc.svg",
  PLACEHOLDER_PORTRAIT: "/assets/56cbb43bb23f7c226f087dd23ef1c1c3.png",
  PLACEHOLDER_SQUARE: "/assets/f6d891acb0c8368549f2d941d4292e50.png",
  GOOGLE_LOGO: "/assets/edd0e34f60d7ca4a2f4ece79cff21ae3.svg",
  KOBLE_LOGO_COLOR_WITH_TEXT: "/assets/16934a4e23bb4c5505f8678740e5fdbb.svg",
  KOBLE_LOGO_COLOR: "/assets/ad9fe9ef4caf433cd5cb03eaef79917b.svg",
  KOBLE_LOGO_COLOR_PNG: "/assets/9fbdf61027303b00604d15758b9ab369.png",
  PURCHASE_SUCCESS: "/assets/6c59b6b4b49459b7e02bc50979a252cb.svg",
  PURCHASE_ERROR: "/assets/6c7dcf9906d652fd61d2761e1ba3efd4.svg",
  LINKEDIN_LOGO: "/assets/646c373fd8f412d09b9daa5e2a4c752d.png",
  INDEED_LOGO: "/assets/693733957ff34052c64b19776f7a9fe1.png",
  OCC_LOGO: "/assets/e6163581cf3a6b37a0b10ccf3978f2d8.png",
  GLASSDOOR_LOGO: "/assets/9d8851de5294df36be0b9a1a73f3527a.png",
  BLURED_PROFILE_IMAGE: "/assets/4dd530d402024e2e7fdfacafe759af12.png",
  MAN_SEARCHING_ORANGE: "/assets/76261189992a7dec1fb13d48679451b3.svg",
  STAR_ORANGE: "/assets/544c9dad80146837a05df6473b6f89c8.svg",
  COMPLETE_PROFILE: "/assets/5663e64ca9bbffaf087a3b98df558353.svg",
  AVATAR_1: "/assets/244322283a8395e0d5ad4ba892acf02f.png",
  AVATAR_2: "/assets/73caefdc46c3e4a09e89b8f10c5feb53.png",
  AVATAR_3: "/assets/82876e771281168cbb5bcaf6b96627d7.png",
  SSO_BACKGROUND: "/assets/654e2f841d91609fa78930828a1a2ff9.jpg",
  KOBLE_LOGO_BLACK_WITH_TEXT: "/assets/34308a4570860b13ffbb7dcc60439b52.svg",
  KOBLE_LOGO_WHITE: "/assets/b86e827e1c8e656f825a6214d66fbb0c.svg",
  KOBLE_LOGO_WHITE_WITH_TEXT: "/assets/d7fd12ad73fa4fe585c6ffafa7f0d817.svg",
  KOBLE_LOGO_WATERMARK: "/assets/23045d9cbe3e36add08c9b6e0b51f892.webp",
  STAR_BLUE: "/assets/404ad30c8713b3606600419175708fa4.svg",
  UPLOADER_ICON: "/assets/8cdf50c5df8597e7ec55bf9397092687.svg",
  KOBOT_HAPPY_EXTRA_SMALL_ORANGE:
    "/assets/9cef85dc55ddc5f2535cba606eded4e3.svg",
  LOADING: "/assets/58d0ca792a4159738427770e9a0032d6.gif",
  SSO_STUDENT: "/assets/cfb4aa8b3a9cd969972990caa82e7a81.jpeg",
  PUBLIC_ORANGE_GRADIENT: "/assets/b82725dbba7f862f48c68b14736511f9.webp",
  PUBLIC_USER_STUDENT_HEADER_IMAGE:
    "/assets/73e0dfb2449971084326ad1e29d0d180.webp",
  PUBLIC_SEARCH_WORK_STEP_1: "/assets/3969f66939831da89ffc8d25ee9cbaab.webp",
  PUBLIC_SEARCH_WORK_STEP_2: "/assets/20d36efc9b8d6c2399c9e578ab37cdb4.webp",
  PUBLIC_SEARCH_WORK_STEP_3: "/assets/483d88527880733cfde9b57d9dc79a5f.webp",
  PUBLIC_KOBLE_BENEFITS_1: "/assets/74921a2410b689fc0dcb0ee1641469b8.webp",
  PUBLIC_KOBLE_BENEFITS_2: "/assets/74c40586f6a6df08e27d1c0be22fe911.webp",
  PUBLIC_KOBLE_BENEFITS_3: "/assets/dd09d1952f2785965de94f6a03a2156e.webp",
  PUBLIC_KOBLE_BENEFITS_4: "/assets/96518de130d9652b17b58844a7dd326f.webp",
  PUBLIC_USER_STUDENT_TESTIMONY_AVATAR:
    "/assets/a9ec1a1c425b1ffa29b50e86507db7e8.webp",
  PUBLIC_USER_RECRUITER_HEADER_IMAGE:
    "/assets/1af7058944e67e9e2256eb5d34a20c69.webp",
  PUBLIC_HIRING_PROCESS_STEP_1: "/assets/1c515b148485703fe7b21c5542055401.webp",
  PUBLIC_HIRING_PROCESS_STEP_2: "/assets/69b6726032fc6e05817d967c09c2d6d8.webp",
  PUBLIC_HIRING_PROCESS_STEP_3: "/assets/06567fa6fed274f2700161ffeda110df.webp",
  PUBLIC_HIRING_PROCESS_STEP_4: "/assets/97a112602e328957435b1035cf266121.webp",
  PUBLIC_USER_RECRUITER_BENEFITS_1:
    "/assets/f2218adc6b99e7454a21b35bb0ebb91b.webp",
  PUBLIC_USER_RECRUITER_BENEFITS_2:
    "/assets/41a9d11e4706463884a6b227580e29f3.webp",
  PUBLIC_USER_RECRUITER_BENEFITS_3:
    "/assets/165529ebd696627b659995e647d1f3b4.webp",
  PUBLIC_USER_RECRUITER_BENEFITS_4:
    "/assets/7fb8570fc1bb3a0a14adf4acfc5c126a.webp",
  PUBLIC_USER_RECRUITER_AI_MATCH:
    "/assets/15ed98e98896e4daa3edba83ab65168b.webp",
  PUBLIC_USER_RECRUITER_TESTIMONY_AVATAR:
    "/assets/e5107295778d9dd195f74f2e275ca0e3.webp",
  PUBLIC_COVER_USER_RECRUITER: "/assets/ded6e9c43364ae29ea26bfb3037972c0.webp",
  PUBLIC_COVER_USER_STUDENT: "/assets/18fbe442ad911a98fee9bd14a1a888d6.webp",
};
